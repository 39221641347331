/* You can add global styles to this file, and also import other style files */

@import '~ng-zorro-antd/ng-zorro-antd.min.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Open Sans', 'Candara', Optima, Arial, sans-serif;
  font-size: 17px;
}

.pointer {
  cursor: pointer !important;
}

:root {
  --primary-button-color: rgb(176, 38, 54);
  --secondary-button-color: rgb(60, 59, 119);
  --primary-red: rgb(176, 38, 54);
  --primary-blue: rgb(57, 57, 114);
  --primary-white: rgb(255, 255, 255);
  --faint-white: rgba(255, 255, 255, 0.8);
  --background-very-light-blue: #edf3f8;
}

/* HEADER & LAYOUT */
.ant-layout-header,
.ant-layout-footer,
.ant-layout-content {
  background-color: white !important;
  padding: 0 !important;
}
.main-content {
  padding: 4vw 10vw;
  &.page-top {
    padding-top: 85px;
  }
}

/* SLIDE OUT MENU */
/* Not used yet
.ant-layout-sider-zero-width-trigger {
  z-index: 100;
  top: 30px;
}
.outer-layout {
  min-height: 100vh;
}
 */

/*  HEADINGS */
h1 {
  font-family: 'Montserrat', 'Open Sans', 'Candara', Optima, Arial, sans-serif;
  font-size: calc(1rem + 1.7vw);
  font-weight: 700;
  line-height: calc(1rem + 2vw);
  color: var(--primary-red);
}
h2 {
  font-size: calc(1rem + 0.4vw);
  font-family: 'Montserrat', 'Open Sans', 'Candara', Optima, Arial, sans-serif;
  font-weight: 700;
}
h3 {
  font-size: calc(0.7rem + 0.3vw);
  font-family: 'Montserrat', 'Open Sans', 'Candara', Optima, Arial, sans-serif;
  font-weight: 700;
}

section.full-width-section {
  width: 55%;
  min-width: 600px;
  max-width: 1200px;
  margin: 15px auto;
}

p,
body,
li {
  font-size: calc(1rem + 0.2vw);
}

p {
  max-width: 720px;
}

.highlight-underline {
  color: var(--primary-blue);
  text-decoration: 2px underline var(--primary-red);
  font-weight: bold;
  letter-spacing: 0.5px;
}

/* COLORS */
.text-bmd-blue {
  color: var(--primary-blue);
}
.text-bmd-red {
  color: var(--primary-red);
}
.text-bmd-white {
  color: var(--primary-white);
}
.text-faint-white {
  color: var(--faint-white);
}
.bg-bmd-blue {
  background-color: var(--secondary-button-color);
}
.bg-bmd-red {
  background-color: var(--primary-button-color);
}
.uncolored {
  color: revert;
}

/* BACKGROUNDS */
.bg-reverse {
  background-color: var(--primary-blue);
  color: var(--faint-white);
}
.bg-triangle {
  background: linear-gradient(
    48deg,
    #fff 0%,
    #fff 66%,
    var(--background-very-light-blue) 66%,
    var(--background-very-light-blue) 100%
  );
}
.bg-pyramid-desktop {
  background: url('./assets/Media-Pyramid_BG.png');
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: auto;
}
.bg-pyramid-mobile {
  background: url('./assets/Media-Pyramid_BG.png');
  background-size: cover;
  height: 180vw;
  min-height: 600px;
  margin-bottom: -20vw;
  margin-top: -20vw;
}

/* UTILITY */
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

.small-text {
  font-size: calc(0.7rem + 0.2vw);
}
.large-text {
  font-size: calc(1rem + 1vw);
}

.underline {
  text-decoration: underline;
}

.margin-bottom-0px {
  margin-bottom: 0;
}
.margin-left-10px {
  margin-left: 10px;
}
.margin-right-15px {
  margin-right: 15px;
}

/* BUTTON */

button {
  min-height: 3.8rem;
}

button.ant-btn-primary {
  height: 4vw;
  font-size: calc(1rem + 0.3vw);
  font-weight: bold;
  text-transform: uppercase;
  min-width: 300px;
  background: var(--primary-blue);
}

// News-Source Specific Styles //

.Democrat {
  color: green;
}
.Republican {
  color: red;
}
.Progressive {
  color: orange;
}
.Foreign {
  color: purple;
}
.Conservative {
  color: yellowgreen;
}

// Article Styles //

article {
  padding: 10px;
  width: 740px;
  margin: 20px auto;

  h1 {
    font-size: 40px;
    font-weight: bold;
  }
  h2 {
    font-size: 24px;
    margin-top: 40px;
  }

  h4 {
    color: #777;
    font-weight: 400;
    font-size: 22px;
  }

  hr {
    border: 0;
    border-top: 1px dashed #bbb;
    margin: 40px auto 60px;
    width: 25%;
  }

  img.food-pyramid {
    padding: 40px 60px 0;
  }
  caption {
    text-align: center;
    display: block;
    margin-bottom: 50px;
  }

  p {
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-size: 21px;
    line-height: 35px;
    margin-top: 25px;
    clear: both;
    &.drop-cap:first-letter {
      float: left;
      line-height: 60px;
      font-size: 80px;
      font-family: Montserrat;
      color: #7d3c98;
      padding: 5px;
      margin-right: 5px;
      margin-bottom: -5px;
    }
  }
}

/* LIGHTBOX POPUPS */

.news-source-modal {
  .ant-modal-content {
    border-radius: 15px;
  }

  .news-source-inner {
    overflow: auto;

    img.bmd-nameplate {
      height: 18px;
    }
    img.media-source-logo {
      float: right;
      width: 35vw;
      max-width: 220px;
      margin: 0 0 20px 20px;
    }
    h1 {
      margin: 35px 0 25px;
    }
    p {
      font-size: 1rem;
      text-align: justify;
    }
    span {
      display: block;
      font-size: 1rem;
      font-style: italic;
    }
    span strong {
      font-style: normal;
      font-weight: 700;
    }
    h2.visit-link {
      margin: 25px 20px 20px 0;
      border: 2px solid var(--primary-red);
      color: var(--primary-red);
      padding: 10px 15px;
      float: left;
    }
  }
}

.featherlight .featherlight-close-icon {
  top: 7px;
  right: 10px;
  font-size: x-large;
}

.featherlight-inner {
  padding: 5px 20px 20px;
}
.featherlight-inner .preFade {
  opacity: 1;
}

/* MEDIA QUERIES */

/** medium - desktop **/
@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}
/** small - mobile **/
@media (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }
  .bg-pyramid-desktop {
    background: none;
  }
  section.full-width-section {
    text-align: left !important;
    width: 100%;
    min-width: 80vw;
    max-width: 100vw;
    margin: 0;
  }
  section#the-diets {
    text-align: right !important;
  }
}
/** x-small - mobile **/
@media (max-width: 350px) {
}
